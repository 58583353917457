import { BaseClass } from './base'
import defaultConfig from '@/config/default.config'

export class FinAccountClass extends BaseClass {
  modelRef = {
    number: ''
  }

  rulesRef = {
    number: [
      {
        required: true,
        message: '请输入卡号'
      }
    ]
  }

  gets (params) {
    return this.get(this.finAccount, params)
  }

  detail (id) {
    return this.get(this.finAccount + `/${id}`)
  }

  detailBymobile (mobile) {
    return this.get(this.finAccount + '/GetSingleByMoile', { mobile })
  }

  consume (data) {
    return this.post(this.finAccount + '/Consume', data)
  }

  charge (data) {
    return this.post(this.finAccount, data)
  }

  update (data) {
    return this.put(this.finAccount, data)
  }

  del (id) {
    return this.delete(this.finAccount + `/${id}`)
  }

  getPersonals () {
    return this.get(this.finAccount + '/GetPersonalFins', { StoreId: defaultConfig.store_id })
  }

  payCode (id) {
    return this.get(this.finAccount + '/FinAccountQrCode', { id })
  }

  boxPay (data) {
    return this.post(this.finAccount + '/BoxPay', data)
  }

  allowCredit (id, isAllowed) {
    return this.put(this.finAccount + `/${id}/${isAllowed}`)
  }

  balance (data) {
    return this.post(this.finAccount + '/ChangeBalance', data)
  }

  cards (params) {
    return this.get(this.finAccount + '/GetMemberCards', params)
  }

  createCards (number) {
    return this.get(this.finAccount + '/CreateMemberCard', { number })
  }

  active (data) {
    return this.post(this.finAccount + '/ActiveMemberCard', data)
  }

  getCard (number) {
    return this.get(this.finAccount + '/MemberCardDetail', { number })
  }

  cardPay (data) {
    return this.post(this.finAccount + '/PayByCard', data)
  }

  delCard (id) {
    return this.delete(this.finAccount + `/DeleteCard/${id}`)
  }

  exportReport (params) {
    return this.export(this.finAccount + '/Export', 'GET', params)
  }
}

export class RechargeClass extends BaseClass {
  gets (params) {
    return this.get(this.recharge, params)
  }

  report (params) {
    return this.get(this.recharge + '/RechargeReport', params)
  }

  exportReport (params) {
    return this.export(this.recharge + '/ExportRechargeReport', 'GET', params)
  }
}
