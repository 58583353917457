const page = {
  data () {
    return {
      searchLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 15 }
        }
      },
      searchLayout2: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 0 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 22 }
        }
      },
      searchLayout3: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      },
      queryParams: {
        Limit: 20
      },
      page: {
        total: 0,
        page: 1
      },
      list: []
    }
  },

  methods: {
    // 翻页
    changePage (e) {
      this.getDataList(e)
    },
    // 容量变化
    onShowSizeChange (current, size) {
      this.queryParams.Limit = size
    },
    // 重置
    reset (callFun) {
      typeof callFun === 'function' && callFun()
    },
    // 添加
    addForm (callFun) {
      typeof callFun === 'function' && callFun()
    },
    // 编辑
    editForm (callFun) {
      typeof callFun === 'function' && callFun()
    },
    // 删除
    delForm (callFun) {
      typeof callFun === 'function' && callFun()
    },
    routeTo (name, params) {
      this.$router.push({
        name,
        params
      })
    }
  }
}

export default page
