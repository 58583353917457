<template>
  <e-modal
    :visible="visible"
    title="添加会员卡"
    :width="400"
    :loading='loading'
    @close="onClose"
    @ok="onSubmit((e) => onSubmitFun(e))"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input
          :labelCol="6"
          title="卡号"
          :validate="validateInfos.number"
          v-model:value="modelRef.number"
        />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import { FinAccountClass } from '@/apis/fin'

const useForm = Form.useForm
const api = new FinAccountClass()
export default defineComponent({
  components: {},
  mixins: [form],
  emits: ['ok'],
  setup () {
    const loading = ref(false)
    const visible = ref(false)
    const title = ref('')

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      modelRef,
      validateInfos,
      resetFields,
      validate,
      onClose
    }
  },

  methods: {
    open () {
      this.visible = true
    },

    onSubmitFun (e) {
      const data = toRaw(e)

      api.createCards(data.number).then(() => {
        this.$message.success('添加会员卡成功')
        this.$emit('ok')
        this.onClose()
      }).catch(() => {
        this.loading = false
      })
    }
  }
})
</script>

<style lang="less" scoped></style>
